.create_client {
    position: relative;
    .upload_engagement {
        height: 100%;
        overflow: auto;
        padding: 40px 20px;
        h1 {
            font-size: 36px;
            line-height: 50px;
            font-weight: 500;
            margin-bottom: 25px;
        }
        &_img {
            padding: 40px;
            max-width: 445px;
            width: 100%;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23005287FF' stroke-width='6' stroke-dasharray='6%2c 14' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e");
            border-radius: 4px;
            &_wrp {
                @media (max-width: 768px) {
                    flex-direction: column;
                }
            }
            img {
                max-width: 345px;
                width: 100%;
            }
            label {
                color: #03639d;
                font-size: 20px;
            }
        }
        &_button {
            max-width: 400px;
            width: 100%;
            button {
                font-size: 18px;
            }
        }
        .close_engagement {
            position: absolute;
            right: 10px;
            top: 15px;
            cursor: pointer;
        }
    }
    .create_client_screen {
        height: 100%;
        overflow: auto;
        padding: 20px;
        &_top {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding: 20px;
            z-index: 999;
            background-color: #fff;
            margin-bottom: 0;
            span {
                cursor: pointer;
            }
        }
        &_wrp {
            max-width: 750px;
            width: calc(100% - 190px);
            padding-top: 60px;
            h2 {
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
            }
            .radiolabel {
                span {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 30px;
                    color: #005287;
                }
            }
            h3 {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
            }
            .disable {
                pointer-events: none;
                opacity: 0.4;
            }
        }
        &_btn {
            width: 190px;
            padding-left: 10px;
            text-align: right;
            &_wrp {
                position: sticky;
                top: 60px;
            }
            button {
                max-width: 190px;
            }
        }
    }
    .contatct_person {
        &_heading {
            background-color: #F5F5F5;
            padding: 5px 10px;
            border: 1px solid #E2E2E2;
            h4 {
                font-size: 14px;
                font-weight: 700;
                line-height: 21px;
                margin-bottom: 0;
            }
            button {
                padding: 0;
                font-size: 14px;
                font-weight: 700;
                line-height: 21px;
                color: #005287;
            }
        }
        table {
            border: 1px solid #E2E2E2;
            width: 100%;
            border-top: 0;
            tr {
                border-bottom: 1px solid #E2E2E2;
            }
            td, th {
                padding: 10px;
                border-right: 1px solid #E2E2E2;
                &:last-child {
                    border-right: 0;
                }
            }
            // td {
            //     word-break: break-word;
            // }
            th {
                padding: 10px
            }
        }
    }
}
.entity {
    .css-boe5wf-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
        padding-bottom: 7px !important;
    }
    &.selected {
        padding-right: 30px !important;
        .css-boe5wf-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
            overflow: auto;
            padding: 8px 14px !important;
            padding-bottom: 4px !important;
        }
    }
    .custom_plus {
        position: absolute;
        z-index: 1;
        background-color: #fafafa;
        right: 2px;
        top: 0;
        bottom: 0;
        height: 25px;
        margin: auto;
    }
}
.nestedlist {
    padding: 20px;
    padding-top: 65px;
    flex-direction: column;
    height: 100%;
    .filter_container {
        border: 1px solid #B4B4B4;
        height: 100%;
        overflow: auto;
        &_left {
            padding: 16px;
            width: 35%;
            display: flex;
            flex-direction: column;
            overflow: auto;
            .rct-text {
                flex-direction: row-reverse;
                justify-content: flex-end;
                padding: 5px;
                input:checked~.rct-title {
                    color: #00A0DC;
                }
                svg {
                    display: none;
                }
            }
            .rct-icon {
                font-size: 17px;
                &-check {
                    &::before {
                        color: #00A0DC;
                    }
                }
            }
            .rct-title {
                font-size: 16px;
                font-weight: normal;
                color: #005287;
            }
            .rct-collapse {
                .rct-icon {
                    font-size: 16px;
                    font-weight: normal;
                }
                svg {
                    width: 24px;
                }
                &.rct-collapse-btn {
                    display: flex;
                }
            }
            .react-checkbox-tree {
                overflow: auto;
                label {
                    display: flex;
                    align-items: flex-start;
                    &:hover {
                        background-color: unset;
                        .rct-title {
                            color: #00A0DC;
                        }
                    }
                }
            }
            .rct-node {
                &.rct-node-expanded {
                    >span {
                        background-color: #F4F4F4;
                    }
                }
            }
        }
        &_right {
            width: 65%;
            padding: 16px;
            border-left: 1px solid #B4B4B4;
            &_definition {
                color: #656565;
                p {
                    font-weight: normal;
                    margin-top: 4px;
                }
            }
        }
    }
}