.homePageLandingContainer {
  .streamlineContainer {
    .streamlineCard1 {
      background-color: #c6d7ff;
      border-color: #96b5ff;
      h4 {
        color: #0034ae;
      }
      button {
        color: #0034ae;
        svg {
          path {
            stroke: #0034ae;
          }
        }
      }
    }
    .streamlineCard2 {
      background-color: #ffdee4;
      border-color: #ffbcc8;
      h4 {
        color: #d14861;
      }
      button {
        color: #d14861;
        svg {
          path {
            stroke: #d14861;
          }
        }
      }
    }
    .streamlineCard3 {
      background-color: #dff7e8;
      border-color: #aae7c1;
      h4 {
        color: #1a6a38;
      }
      button {
        color: #1a6a38;
        svg {
          path {
            stroke: #1a6a38;
          }
        }
      }
    }
    .streamlineCard4 {
      background-color: #fef2d5;
      border-color: #f7c53b;
      h4 {
        color: #c6900a;
      }
      button {
        color: #c6900a;
        svg {
          path {
            stroke: #c6900a;
          }
        }
      }
    }
  }
  .howToDigiesignContainer {
    .howToDigiEsignLeftStepCard {
      svg {
        width: 32px;
        height: 32px;
      }
    }
    .howToDigiEsignLeftStepCard.active {
      svg {
        path {
          stroke: #3b82f6;
        }
      }
    }
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .letsGrowTogetherSection {
    height: auto;

    @media (min-width: 640px) {
      height: 600px;
    }
  }
}

.digiesignSignUpForm {
  .halfWidth {
    width: 100%;
    @media (min-width: 640px) {
      width: 49%;
    }
  }
}

.digiesignSignUpForm::-webkit-scrollbar {
  width: 6px; // For vertical scrollbar
  height: 12px; // For horizontal scrollbar
}

/* Style the track (background of the scrollbar) */
.digiesignSignUpForm::-webkit-scrollbar-track {
  background-color: transparent; // Light gray background color
  border-radius: 20px; // Rounded corners
  opacity: 1;
}

/* Style the thumb (the draggable part of the scrollbar) */
.digiesignSignUpForm::-webkit-scrollbar-thumb {
  background-color: #d9d9d9; // Semi-transparent black thumb
  border-radius: 20px; // Rounded corners
}

/* Hover effect on the thumb */
.digiesignSignUpForm::-webkit-scrollbar-thumb:hover {
  background-color: #d9d9d9; // Darker thumb when hovered
}

/* Optional: Style the corner (where horizontal and vertical scrollbars meet) */
.digiesignSignUpForm::-webkit-scrollbar-corner {
  background-color: #d9d9d9;
}

.sun-editor {
  border: none !important;
  background: black !important;
}
.se-read-only {
}
