.esign {
  max-width: 1900px;
  margin: 0px auto;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  &_header {
    gap: 20px;
    &_right {
      button {
        &:first-child {
          border: 1px solid #216dde;
          background: #ffffff;
          width: 91px;
          height: 40px;
          padding: 10px, 20px, 10px, 20px;
          border-radius: 10px;
          color: #216dde;
        }
        &:last-child {
          width: 125px;
          height: 38px;
          border: 1px solid transparent;
          background: #216dde;
          padding: 10px;
          border-radius: 10px;
          border: 0px, 0px, 4px, 0px;
          color: white;
        }
        &:last-child:disabled {
          background: #a0a0a0;
        }
      }
    }
  }
  &_content {
    gap: 20px;
    height: calc(100vh - 200px);
  }
  .custom_btn {
    width: 150px;
    height: 40px;
    padding: 12px;
    border-radius: 10px;
    border: 0px, 0px, 4px, 0px;
    gap: 10px;
    background: #216dde;
    color: #fff;
    border: none;
    border-bottom: 4px solid #184ea0;
    font-size: 16px;
    line-height: 12px;
  }
  &_right_side {
    // position: absolute;
    // right: 0;
    // top: 0;
    margin-top: 0 !important;
    min-width: 251px;
    max-width: 251px;
    width: 100%;
    display: flex;
    // align-items: flex-end;
    gap: 10px;
    &_sign {
      width: 100%;
      height: max-content;
      border: 1px solid #d1d9e0;
      border-radius: 10px;
      background-color: #fff;
      .drag-blocks {
        &-select {
          padding: 16px;
          border-bottom: 1px solid #d1d9e0;
        }
      }
      .dragging-blocks {
        .drag-main-box {
          border-bottom: 1px solid #d1d9e0;
          &:last-child {
            border: none;
          }
          .draggable {
            padding: 16px;
          }
        }
      }
    }
    .finish_btn {
      width: 150px;
      height: 40px;
      border: 1px solid transparent;
      background: #216dde !important;
      padding: 10px;
      border-radius: 10px;
      border: 0px, 0px, 4px, 0px;
    }
    .finish_btn:disabled {
      background: #a0a0a0 !important;
    }
  }
  .steps {
    &_container {
      display: flex;
      align-items: center;
      font-family: 'Satoshi';
      gap: 2px;
      &.active,
      &.completed {
        .steps_container_outline {
          background: #dee9fa !important;
          .steps_container_outline_two {
            background: #bbd3f6 !important;
            span {
              border: 2px solid #216dde !important;
            }
          }
        }
      }
      &_outline {
        background: transparent;
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        @media screen and (max-width: 1440px) {
          width: 42px;
          height: 42px;
        }
        &_two {
          background: transparent;
          width: 46px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          @media screen and (max-width: 1440px) {
            width: 36px;
            height: 36px;
          }
          span {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #d1d9e0;
            background-color: #fff;
            border-radius: 10px;
            @media screen and (max-width: 1440px) {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
      label {
        font-family: 'Satoshi';
        font-size: 13px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #101010;
        @media screen and (max-width: 1440px) {
          font-size: 10px;
        }
      }
      &.completed {
        .steps_container_outline {
          background: transparent !important;
          .steps_container_outline_two {
            background: transparent !important;
            span {
              border: 1px solid #216dde;
              background: #216dde;
              color: #fff;
            }
          }
        }
        label {
          color: #216dde;
        }
      }
    }
    .MuiStepConnector-horizontal {
      span {
        border-color: #d1d9e0 !important;
        border-top-width: 2px !important;
      }
      &.Mui-active,
      &.Mui-completed {
        span {
          border-color: #216dde !important;
        }
      }
    }
  }
  .document-upload {
    border: 2px dashed #d1d9e0;
    background-color: white;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // max-height: 250px;
    .uplaodfile_preview {
      .uplaodfile_preview_container {
        width: 191px;
        height: 260px;
        padding: 8px;
        border: 1px solid #d1d9e0;
        border-radius: 10px;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
          box-shadow: 0px 2px 8px 0px #0000001f;
        }
        .remove_pdf {
          position: absolute;
          top: 8px;
          right: 10px;
          background: #000000bf;
          color: #fff;
          width: 30px;
          height: 30px;
          padding: 5px;
          border-radius: 50%;
          cursor: pointer;
        }
        .circleloader {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .upaload_btn {
        width: 191px;
        height: 260px;
        padding: 8px;
        border: 1px dashed #216dde;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-family: 'Satoshi';
        gap: 10px;
        color: #216dde;
        font-family: 'Satoshi';
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
  &_left_side {
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    overflow: auto;
    .addsign_pdf_tumb {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      &_container {
        width: 117px;
        height: 144px;
        border-radius: 10px;
        background: #00000080;
        padding: 11px;
        border: 2px solid transparent;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
        }
        &.active {
          background-color: #fff;
          border: 2px solid #216dde;
          img {
            box-shadow: 0px 2px 8px 0px #0000001f;
          }
        }
      }
    }
  }
  .addsign {
    &_area {
      -webkit-box-flex: 1;
      flex-grow: 1;
      position: relative;
      overflow: scroll;
      padding: 0px 30px 0px 30px;
      padding-top: 0;
      margin-top: 0;
      background-color: #aeaeae;
      .drag-area {
        display: flex;
        flex-direction: row;
        background-color: #aeaeae;
        .svg-container {
          flex-grow: 1;
          position: relative;
          svg {
            width: 100%;
            height: 100%;
            image {
              width: 100%;
            }
          }
          #signId {
            img {
              // margin-top: 5px;
              width: 100%;
              &:first-child {
                margin-top: 0 !important;
              }
            }
          }
        }
      }
      .signIdDrag {
        &_container {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          image {
            width: 100%;
          }
        }
      }
    }
  }
  &_center_side {
    border-radius: 10px;
    h4 {
      background-color: #fff;
      display: block;
      padding: 10px 30px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-bottom: 0;
      font-family: 'Satoshi';
      font-size: 20px;
    }
    .addsign_area {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: auto;
      &::-webkit-scrollbar {
        background: transparent;
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ffffff !important;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: transparent !important;
      }
    }
  }
}
.doc-sign-container {
  .main-area {
    position: relative;
    .node-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      button {
        width: 85px;
        height: 35px;
      }
      img {
        object-fit: contain;
      }
    }
    .image-container {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.sigPadContainer {
  .sigCanvas {
    width: 100%;
    height: 100%;
  }
}
.interation {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #d1d9e0;
  overflow: auto;
  &_content {
    &_preview {
      margin-top: 10px;
      &_content {
        height: 125px;
        border: 1px solid #d1d9e0;
        border-radius: 10px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 90px;
          object-fit: contain;
        }
      }
      .sigPadContainer {
        height: 125px;
        border: 1px solid #d1d9e0;
        border-radius: 10px;
        margin-bottom: 15px;
      }
    }
  }
}
.drag-main-box {
  .draggable {
    cursor: grab;
  }
}

.pdf_recepient_dropdown {
  border-radius: 10px !important;
  fieldset {
    border-radius: 10px !important;
  }
}

.uploadDocText {
  color: #101010;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.uploadDocBtn {
  gap: 10px !important;
  svg {
  }
}

.esignUploadStepContainer {
  .pdfLoadingView {
    h4 {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.7px;
      text-align: center;
      padding-top: 10px;
    }
  }
}

.receiverEsignCanvas {
  .esignPdfThumbContainer {
    height: calc(-20px + 100vh) !important;
  }
  .pdfScrollCustom {
    height: calc(-78px + 100vh) !important;
  }
}

.success-screens-container {
  h2 {
    color: #101010;
    text-align: center;
    font-family: 'Satoshi';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 125% */
  }
  p {
    color: #606060;
    font-family: 'Satoshi';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
  }
}

.reviewAndStepContainer {
  h4 {
    color: #303030;
    font-family: 'Satoshi';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    margin-bottom: 14px;
  }
}

.confirmSendingDocPopup {
  h4 {
    font-size: 20px;
    font-weight: 700;
    font-family: 'Satoshi';
  }
}

.esignMainListContainer {
  table {
    thead {
      th {
        background-color: #f8f9fa !important;
        height: 50px;
        padding: 10px !important;
      }
    }
    tbody {
      tr {
        height: 70px;
        td {
          padding: 10px !important;
        }
      }
    }
  }
}

#esign_document_canvas_container {
  position: relative;
  width: 100%;
  height: 100%;
}
