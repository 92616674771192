.purchase {
    gap: 10px;
    font-family: 'Roboto', sans-serif;
    &_left {
        width: calc(100% - 275px);
        height: calc(100vh - 25vh);
        max-height: 900px;
        overflow: auto;
        table {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-collapse: collapse;
            background-color: rgba(255, 255, 255, 1);
            width: 100%;
            td, th {
                border: 1px solid rgba(0, 0, 0, 0.3);
                text-align: center;
                padding: 15px 20px;
                vertical-align: top;
                text-transform: capitalize;
            }
            tr th {
                border: 1px solid rgba(0, 0, 0, 0.3);
                text-align: center;
                padding: 6px 20px;
                background-color: #818181;
                color: #fff;
                text-transform: uppercase;
                font-size: 16px;
            }
            thead, th {
                position: sticky;
                top: -1px;
                z-index: 2;
            }
            .plan_title {
                font-size: 15px;
            }
            .plan_section {
                ul {
                    padding: 0;
                    margin: 0;
                    display: flex;
                    list-style: none;
                    gap: 15px;
                    justify-content: space-between;
                    li {
                        width: 50%;
                        min-width: 120px;
                        input {
                            display: none;
                            &:checked~label {
                                border-color: #005287;
                                background-color: #E3F5FD;
                            }
                        }
                        label {
                            width: 100%;
                            height: 100px;
                            border: 1px solid transparent;
                            background-color: #F2F2F2;
                            border-radius: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            cursor: pointer;
                            h4 {
                                font-size: 16px;
                                font-weight: 600;
                                color: #005287;
                            }
                            span {
                                font-size: 12px;
                                color: #555555;
                            }
                        }
                    }
                }
                button {
                    height: 43px;
                    box-shadow: unset;
                    font-size: 12px;
                    &:disabled {
                        opacity: 0.7;
                        color: #fff;
                        cursor: not-allowed;
                    }
                }
            }
            .plan_info {
                .product_slider {
                    width: 25vw;
                    padding: 0 10px;
                    // margin: 0 auto;
                    margin-bottom: 15px;
                    &_item {
                        padding: 0 4px;
                        margin: auto;
                        max-width: 69px;
                        img {
                            width: 61px;
                            height: 61px;
                            object-fit: contain;
                            display: block;
                            margin: auto;
                        }
                        .text_img {
                            width: 61px;
                            height: 61px;
                            object-fit: cover;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #9E9E9E;
                            border-radius: 4px;
                            margin: auto;
                            font-size: 32px;
                            color: #33759F;
                        }
                        label {
                            margin-top: 5px;
                            font-size: 10px;
                            text-transform: uppercase;
                            text-align: center;
                            display: block;
                        }
                    }
                    .single {
                        display: flex;
                        overflow: auto;
                        .product_slider_item {
                            margin: unset;
                        }
                    }
                    .slick-prev, .slick-next {
                        width: auto;
                        height: auto;
                    }
                    .slick-prev:before {
                        content: '\f104';
                        font-family: "Font Awesome 5 Pro";
                        font-size: 28px;
                        font-weight: 400;
                        color: #005287;
                    }
                    .slick-next:before {
                        content: "\f105";
                        font-family: "Font Awesome 5 Pro";
                        font-size: 28px;
                        font-weight: 400;
                        color: #005287;
                    }
                }
                p {
                    font-size: 12px;
                }
            }
        }
    }
    &_right {
        width: 270px;
        margin-left: 0 !important;
        &_header {
            align-items: unset;
            button {
                width: 100%;
                height: 46px;
                &:first-child {
                    box-shadow: unset;
                }
                &:last-child {
                    background-color: #fff !important;
                    &.active {
                        background-color: #fff !important;
                        border-color: #2196f3;
                        box-shadow: 0px 0px 12px 0px #00A0DC4D;
                    }
                    >div {
                        position: relative;
                        .cart_count {
                            position: absolute;
                            background-color: #005287;
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            font-size: 9px;
                            color: #fff;
                            right: -4px;
                            top: -5px;
                        }
                    }
                }
            }
        }
        .minicart {
            background-color: #fff;
            margin-top: 10px;
            border-radius: 4px;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
            &_header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 12px 10px;
                border-bottom: 1px solid #00000033;
                h5 {
                    color: #555555;
                    font-size: 14px;
                    margin-bottom: 0;
                    font-weight: 600;
                }
                span {
                    color: #005287;
                    font-weight: 500;
                    font-size: 14px;
                }
            }
            &_product {
                padding: 16px 0px;
                padding-bottom: 0;
                padding-top: 5px;
                padding-bottom: 5px;
                max-height: 240px;
                overflow: auto;
                border-bottom: 1px solid #00000033;
                ul {
                    list-style: none;
                    padding: 0;
                    margin-bottom: 0;
                    li {
                        padding: 10px;
                        margin-bottom: 5px;
                    }
                }
                &_info {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 8px;
                    h4 {
                        font-size: 16px;
                        font-weight: 700;
                        color: #555555;
                        max-width: calc(100% - 25px);
                        margin-bottom: 0;
                    }
                    .remove_product {
                        cursor: pointer;
                    }
                }
                &_total {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    input.simpleniput {
                        width: 85px;
                        height: 32px;
                        border-radius: 4px;
                        border: 1px solid #0000004D;
                        background-color: #0000000d;
                        color: #555555;
                        text-align: center;
                        &:focus {
                            outline: none;
                        }
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                    &_left {
                        width: 144px;
                        div.minicart_product_total_left_wrp {
                            margin-bottom: 10px;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: column;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            label {
                                font-size: 12px;
                                margin-bottom: 3px;
                            }
                        }
                        div.selectDate {
                            margin: 5px 0;
                            margin-top: 15px;
                            input {
                                padding: 10.5px 6px;
                                padding-right: 1px;
                            }
                            .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
                                padding: 3px;
                            }
                            .css-1nvf7g0 {
                                margin-left: 0;
                            }
                            svg {
                                width: 17px;
                                height: 17px;
                            }
                        }
                    }
                    &_right {
                        color: #555555;
                        text-align: right;
                        width: 100%;
                        max-width: calc(100% - 144px);
                        display: flex;
                        flex-direction: column;
                        p {
                            font-size: 16px;
                            font-weight: 600;
                        }
                        span {
                            font-size: 12px;
                            font-weight: 500;
                            text-transform: capitalize;
                        }
                    }
                }
            }
            &_subtotal {
                padding: 16px 10px;
                padding-bottom: 10px;
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 5px;
                        align-content: center;
                        span {
                            width: 50%;
                            &:first-child {
                                font-size: 14px;
                                text-transform: capitalize;
                            }
                            &:last-child {
                                text-align: right;
                            }
                        }
                        &.subtotal {
                            font-size: 14px;
                            font-weight: 600;
                            color: #555555;
                            span:last-child {
                                font-size: 16px;
                            }
                        }
                        &.tax {
                            span {
                                &:first-child {
                                    color: #005287;
                                }
                                &:last-child {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
            .secure {
                padding: 10px;
                p {
                    font-size: 10px;
                    color: #555555;
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    svg {
                        margin-right: 5px;
                    }
                }
            }
            &_submit {
                padding: 10px;
                padding-bottom: 20px;
                button {
                    box-shadow: unset;
                    font-size: 12px;
                }
            }
        }
    }
}


input.simpleniput {
    width: 85px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #0000004D;
    background-color: #0000000d;
    color: #555555;
    text-align: center;
    &:focus {
        outline: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}