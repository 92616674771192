.checkout {
    .checkout_info {
        background-color: #F8F8F8;
        padding: 20px;
        max-width: 752px;
        &_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 10px;
            h5 {
                color: #505050;
                font-size: 16px;
                line-height: 16px;
                margin-bottom: 0;
                font-weight: 500;
            }
            span {
                color: #005287;
                font-weight: 500;
                font-size: 14px;
            }
        }
        &_product {
            padding: 16px 10px;
            padding-bottom: 0;
            padding-top: 5px;
            padding-bottom: 5px;
            max-height: 240px;
            overflow: auto;
            border-bottom: 1px solid #00000033;
            &_wrp {
                padding: 0 20px;
            }
            ul {
                list-style: none;
                padding: 0;
                margin-bottom: 0;
                li {
                    padding: 10px 0;
                    margin-bottom: 5px;
                }
            }
            &_info {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 8px;
                h4 {
                    font-size: 16px;
                    font-weight: 700;
                    color: #555555;
                    max-width: calc(100% - 25px);
                    margin-bottom: 0;
                }
                .remove_product {
                    cursor: pointer;
                }
            }
            &_total {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                input.simpleniput {
                    width: 85px;
                    height: 32px;
                    border-radius: 4px;
                    border: 1px solid #0000004D;
                    background-color: #0000000d;
                    color: #555555;
                    text-align: center;
                    &:focus {
                        outline: none;
                    }
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
                &_left {
                    width: calc(100% - 235px);
                    div.checkout_info_product_total_left_wrp {
                        margin-bottom: 10px;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;
                        align-items: center;
                        gap: 10px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        label {
                            font-size: 12px;
                            margin-bottom: 3px;
                            font-weight: 400;
                        }
                    }
                    div.selectDate {
                        margin: 10px 0;
                        margin-top: 15px;
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        font-size: 12px;
                        label {
                            font-size: 12px;
                            margin-bottom: 3px;
                            font-weight: 400;
                        }
                        p {
                            cursor: default;
                        }
                        input {
                            padding: 10.5px 6px;
                        }
                        .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
                            padding: 3px;
                        }
                        svg {
                            width: 17px;
                            height: 17px;
                        }
                    }
                }
                &_right {
                    color: #555555;
                    text-align: right;
                    width: 100%;
                    max-width: 230px;
                    display: flex;
                    flex-direction: column;
                    p {
                        font-size: 16px;
                        font-weight: 600;
                    }
                    span {
                        font-size: 12px;
                        font-weight: 500;
                        text-transform: capitalize;
                    }
                }
            }
        }
        &_subtotal {
            padding: 16px 10px;
            padding-bottom: 10px;
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    display: flex;
                    justify-content: space-between;
                    margin: 11px 0;
                    align-content: center;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        width: 50%;
                        &:first-child {
                            font-size: 14px;
                            text-transform: capitalize;
                        }
                        &:last-child {
                            text-align: right;
                        }
                    }
                    &.subtotal {
                        font-size: 14px;
                        font-weight: 600;
                        color: #555555;
                        display: flex;
                        align-items: center;
                        margin: 0;
                        margin-bottom: 10px;
                        span:last-child {
                            font-size: 16px;
                        }
                    }
                    &.total {
                        font-size: 14px;
                        font-weight: 600;
                        color: #555555;
                        span {
                            color: #000000;
                            font-size: 20px;
                            font-weight: 600;
                        }
                        label {
                            display: block;
                            color: #555555;
                            font-size: 16px;
                        }
                    }
                    &.tax {
                        span {
                            &:first-child {
                                color: #005287;
                            }
                            &:last-child {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            &.totalinfo {
                padding: 0 10px;
            }
        }
        .billingdata {
            padding: 0 10px;
            input {
                background: #fff !important;
            }
            .couponcode {
                h6 {
                    font-weight: normal;
                    font-size: 16px;
                }
                >div {
                    position: relative;
                    button {
                        position: absolute;
                        right: 2px;
                        height: calc(100% - 4px);
                        width: 120px;
                        background-color: #E3F5FD;
                        border-left: 1px solid #00528733;
                        top: 0px;
                        bottom: 0;
                        margin: auto;
                        border-radius: 3px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        &.remove_coupon {
                            background-color: #fde3e3;
                            border-left: 1px solid rgb(135 0 0 / 20%);
                            color: red;
                        }
                    }
                }
            }
        }
        &_submit {
            padding: 10px;
            margin-top: 20px;
            button {
                box-shadow: unset;
                font-size: 12px;
                color: #fff !important;
                &:disabled {
                    opacity: 0.5;
                }
            }
        }
    }
}
.invoice {
    &_main_wrp {
        border: 1px solid #0000004D;
        max-width: 767px;
        position: relative;
        .invoice_btn {
            position: absolute;
            right: 40px;
            top: 32px;
        }
    }
    max-width: 767px;
    background-color: #fff;
    height: 100%;
    padding: 32px 40px;
    padding-right: 23px;
    &_title {
        padding: 0;
    }
    h4 {
        font-size: 24px;
    }
    &_wrp {
        padding: 0 20px;
    }
    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 10px;
        h5 {
            color: #505050;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 0;
            font-weight: 500;
        }
    }
    &_info {
        margin-top: 20px;
        &_header {
            margin-bottom: 22px;
            h5 {
                font-size: 16px;
                font-weight: 500;
            }
        }
        &_add {
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            ul {
                padding-left: 0;
                list-style: none;
                max-width: 300px;
                width: 50%;
                margin-bottom: 0;
                li {
                    display: flex;
                    gap: 10px;
                    align-items: baseline;
                    margin-bottom: 16px;
                    label {
                        width: 135px;
                        font-size: 16px;
                        color: #005287;
                        font-weight: 700;
                        line-height: 16px;
                    }
                    p {
                        font-size: 12px;
                        color: #505050;
                        width: calc(100% - 135px);
                    }
                }
                &:first-child {
                    li {
                        justify-content: flex-start;
                        label {
                            width: 75px;
                        }
                        p {
                            max-width: 150px;
                        }
                    }
                }
            }
        }
        &_table {
            width: 100%;
            margin-bottom: 24px;
            padding: 0 20px;
            table {
                width: 100%;
                td, th {
                    text-align: center;
                    padding: 11px 12px;
                    vertical-align: top;
                    text-transform: capitalize;
                    &:first-child {
                        text-align: left;
                    }
                }
                tr th {
                    text-align: center;
                    padding: 6px 12px;
                    background-color: #E3F5FD;
                    color: #005287;
                    text-transform: capitalize;
                    font-size: 16px;
                    font-weight: 700;
                }
                td {
                    font-size: 12px;
                    color: #101010;
                    font-weight: 400;
                    padding: 16px 12px;
                    border-bottom: 1px solid #0000001F;
                }
            }
        }
        &_footer {
            padding: 0 20px;
            display: flex;
            margin-left: auto;
            width: 100%;
            justify-content: flex-end;
            ul {
                min-width: 190px;
                padding-left: 0;
                list-style: none;
                padding-right: 24px;
                li {
                    display: flex;
                    gap: 25px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #101010;
                    margin-bottom: 8px;
                    label {
                        width: 120px;
                    }
                    p {
                        text-align: right;
                        width: calc(100% - 98px);
                    }
                    &.total {
                        font-size: 16px;
                        font-weight: 600;
                        color: #005287;
                    }
                }
            }
        }
    }
}
.billingautocomplete {
    >div {
        padding: 7.4px 9px !important;
        background-color: #fff;
    }
    input {
        padding: 2.5px 50px 2.5px 8px !important;
    }
}