*:not(.freeTextSignTextPreview) {
  font-family: Inter !important;
}
main {
  background-color: white !important;
  margin-left: 0px !important;
  width: 100% !important;
}
section {
  padding: 0px;
}
.dashboardHome {
  .noActivitySection {
    div {
      width: 350px;
      margin-top: 30px;
      h2 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #444444;
        line-height: 31px;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #a0a0a0;
        line-height: 20px;
        margin-top: 20px;
      }
    }
  }
}
.esignUploadDocuments {
  .uploadContainer {
    // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    // border-radius: 10px;
    h3 {
      color: #444444;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      margin-top: 10px;
    }
  }
}

.esignAddRecipeintPage {
  svg {
    cursor: pointer;
  }
  h2 {
    font-size: 20px;
    font-weight: 700;
    color: #101010;
    line-height: 24px;
  }
  .addReciepientBtn {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #3b82f6;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    cursor: pointer;
  }
}

.addReciepientCollapseHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.esignAddMessageSection {
  .esignAppInput {
    width: 100%;
    height: 36px;
    border-radius: 10px;
    padding: 0px 12px;
    border: 1px solid #d4d4d8;
    outline: none;
  }
  .esignAppTextArea {
    width: 100%;
    outline: none;
    padding: 5px 12px;
    border-radius: 10px;
    height: 100px;
    border: 1px solid #d4d4d8;
  }
}

.homeDashboardCard {
  border: 1px solid #e5e7eb;
  border-left: none;
  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #5c5c5c;
  }
  h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 24px;
    color: #181818;
    margin-top: 30px;
  }
}
.placefieldRightSideBar {
  width: 100%;
  .esignCommonDropdownBtn {
    height: 36px !important;
    width: 100%;
  }
  .dropdownList {
    width: 100% !important;
    box-shadow: 0px 10px 15px -3px #0000001a;
    box-shadow: 0px 4px 6px 0px #0000000d;
    border: 1px solid #dddee0;
  }
}

.commonDropdownItemDefault {
  :focus {
    color: white !important;
  }
}

.placeholderItem {
  box-shadow: 0px 1px 3px 0px #0000001a;
  box-shadow: 0px 1px 2px -1px #0000001a;
  border: 0px, 0px, 0px, 3px;
  border-left: 3px solid #c78447;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
}

.placeholderItem.placeholderItemActive {
  border: 2px solid #3b82f6;
}
.placefieldRightSideBarContainer {
  height: calc(100vh - 50%);
}

.documentCreateAccordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  svg {
    cursor: pointer;
  }
  h2 {
    font-size: 20px;
    font-weight: 700;
    color: #101010;
    line-height: 24px;
  }
}

.esignConfirmSendingDocPopup {
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-top: 10px;
    max-width: 300px;
  }
}

.addSignPanel {
  width: 100%;
  margin-top: 20px;
  .esignCommonDropdownBtn {
    height: 36px !important;
    width: 100%;
  }
  .dropdownTitle {
    font-size: 14px;
    font-weight: 600;
    color: #030712;
    margin-bottom: 8px;
  }
  .commonDropdownItemContainerDefault {
    width: 100%;
    z-index: 999;
  }
}

.signConsentPopup {
  .checkboxTitle {
    align-items: start;
    justify-content: start;
    margin-top: 10px;
    input {
      margin-top: 2px;
    }
  }
}

.otherActionsDropdown {
  .esignCommonDropdownBtn {
    width: 100%;
    height: 36px;
  }
  .commonDropdownItemContainerDefault {
    width: 100%;
  }
}

.reviewDocForRecipientConsent {
  .otherActionsDropdown {
    width: 100%;
  }
}

.finishBtnContainer {
  #esignDocumentCreateProceedButton {
    width: 60%;
  }
}

.esignAppFreeTextSign {
  div {
    width: 100%;
    button {
      height: 36px;
      width: 100%;
      h4 {
        color: #9ca3af;
      }
      svg {
        path {
          stroke: #9ca3af;
        }
      }
    }
    .primaryButton {
      height: auto;
    }
    .secondyAppButton {
      height: auto;
    }
    .commonDropdownItemContainerDefault {
      z-index: 999;
    }
  }
}

.removePlaceholderValue {
  button {
    width: 100%;
  }
}

.documentsActiveSidebarLink {
  svg {
    path {
      stroke: #3b82f6;
    }
  }
}

.finishBtnContainer {
  .esignConfirmSendingDocPopup {
    p {
      margin-top: 10px !important;
    }
  }
  .primaryPopupBtnContainer {
    margin-top: 20px;
  }
}

.proceedNExitContainer {
  .primaryButton {
    width: 70%;
    font-size: 14px;
    font-weight: 600;
  }
  .secondyAppButton {
    width: 30%;
    font-size: 14px;
    font-weight: 600;
  }
}

.esignAddRecipeintPage {
  .addReciepientBtn {
    justify-content: flex-start;
    margin-top: 24px;
    padding-left: 20px;
  }
}

.dasboardCardItem {
  .primaryButton {
    height: 50px;
    font-size: 16px;
    min-width: 191px;
    @media (min-width: 1452px) {
      width: 70%;
    }
    width: auto;
  }
}

.dashboardHome {
  .esignUploadBtn {
    width: 100%;
    max-width: 220px;
    margin: 0px;
  }
}
.selectTimeCustomScrollbar::-webkit-scrollbar {
  width: 6px; // For vertical scrollbar
  height: 12px; // For horizontal scrollbar
}

/* Style the track (background of the scrollbar) */
.selectTimeCustomScrollbar::-webkit-scrollbar-track {
  background-color: transparent; // Light gray background color
  border-radius: 20px; // Rounded corners
  opacity: 1;
}

/* Style the thumb (the draggable part of the scrollbar) */
.selectTimeCustomScrollbar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9; // Semi-transparent black thumb
  border-radius: 20px; // Rounded corners
}

/* Hover effect on the thumb */
.selectTimeCustomScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #d9d9d9; // Darker thumb when hovered
}

/* Optional: Style the corner (where horizontal and vertical scrollbars meet) */
.selectTimeCustomScrollbar::-webkit-scrollbar-corner {
  background-color: #d9d9d9;
}

.profile_setting_menu {
  &:hover {
    path {
      stroke: #3b82f6;
    }
  }
  &.activeMenu {
    svg {
      path {
        stroke: #3b82f6;
      }
    }
    li {
      a {
        color: #3b82f6;
      }
    }
    background-color: #f0f9ff;
  }
}

.library_type_dropdown {
  .esignCommonDropdownBtn {
    height: 36px !important;
  }
}
