.mysubscription {
    &_title {
        h4 {
            color: #505050;
            font-size: 24px;
            line-height: 24px;
            font-weight: 700;
            margin-bottom: 24px;
        }
    }
    .current_subscription {
        display: flex;
        // align-items: flex-start;
        gap: 24px;
        width: 100%;
        margin-bottom: 40px;
        &_product {
            background-color: #fff;
            border: 1px solid #00000033;
            padding: 16px;
            border-radius: 4px;
            display: flex;
            gap: 15px;
            &_left {
                display: none;
                width: 210px;
                height: 210px;
                border-radius: 4px;
                border: 1px solid #00000033;
                img {
                    display: block;
                    width: 100%;
                    height: 210px;
                    object-fit: contain;
                    border: none;
                }
            }
            &_right {
                // width: calc(100% - 225px);
                width: 100%;
                display: flex;
                flex-direction: column;
                h6 {
                    color: #005287;
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 500;
                    margin-top: 4px;
                    margin-bottom: 33px;
                    display: block;
                }
                &_wrp {
                    display: flex;
                    flex-wrap: nowrap;
                    overflow: auto;
                    gap: 20px;
                    padding: 0 15px;
                    &_product {
                        width: 94px;
                        position: relative;
                        margin: 6px 6px;
                        overflow: visible;
                        margin-top: 10px;
                        img {
                            width: 94px;
                            height: 94px;
                            object-fit: contain;
                            display: block;
                            margin-bottom: 5px;
                            border-radius: 4px;
                        }
                        h4 {
                            padding: 0 2px;
                            text-align: center;
                            font-size: 12px;
                            line-height: 18px;
                            font-weight: 500;
                            color: #505050;
                            margin-bottom: 0;
                        }
                        span {
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            background-color: #00BC35;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            top: -9px;
                            right: -8px;
                            z-index: 3;
                        }
                    }
                }
            }
        }
        &_action {
            display: flex;
            align-items: center;
            gap: 10px;
            &_item {
                background-color: #fff;
                border-radius: 4px;
                &_img {
                    padding: 10px 8px;
                    padding-bottom: 0;
                    img {
                        width: 100%;
                        display: block;
                        border-top-left-radius: 2px;
                        border-top-right-radius: 2px;
                    }
                }
            }
            button {
                background: linear-gradient(92.46deg, #005287 -1.39%, #00A0DC 98.38%);
                width: 100%;
                height: 53px;
                border: none;
                text-align: center;
                color: #fff;
                font-size: 16px;
                line-height: 16px;
                font-weight: 500;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
    .subscr_inl {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        align-items: flex-start;
        &_item {
            background-color: #fff;
            border: 1px solid #00000033;
            padding: 20px;
            border-radius: 4px;
            // max-width: 320px;
            width: 100%;
            &_img {
                display: flex;
                gap: 17px;
                align-items: center;
                margin-bottom: 16px;
                img {
                    width: 92px;
                    height: 92px;
                    object-fit: contain;
                    border-radius: 4px;
                }
                >div {
                    width: calc(100% - 109px);
                    h3 {
                        color: #33759F;
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                    span {
                        font-size: 12px;
                        line-height: 17px;
                        display: inline-block;
                    }
                }
            }
            &_data {
                &_action {
                    display: flex;
                    flex-wrap: nowrap;
                    gap: 10px;
                    button {
                        border: none;
                        height: 38px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;
                    }
                    .assign_btn {
                        // max-width: 220px;
                        width: 100%;
                        padding: 5px 10px;
                        background-color: #E3F5FD;
                        color: #005287;
                        gap: 10px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 18px;
                    }
                    .dropdown_btn {
                        width: 52px;
                    }
                }
                &_wrp {
                    margin-top: 20px;
                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        li {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 14px 12px;
                            border-bottom: 1px solid #0000001A;
                            &:last-child {
                                border-bottom: 0;
                                padding-bottom: 0;
                            }
                            &.heading {
                                background-color: #F4F4F4;
                                padding: 11px 12px;
                                border-radius: 4px;
                                border: none;
                                label {
                                    width: 50%;
                                    color: #101010;
                                    font-size: 16px;
                                    line-height: 16px;
                                    font-weight: 500;
                                    &:last-child {
                                        width: 70px;
                                    }
                                }
                            }
                            span {
                                color: #707070;
                                font-size: 16px;
                                line-height: 18px;
                                font-weight: 400;
                                &:first-child {
                                    width: calc(100% - 85px);
                                }
                                &:last-child {
                                    width: 70px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
////// noplan subscription  
.noplan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 768px;
    height: 82vh;
    margin: auto;
    h4 {
        color: #005287;
        font-size: 24px;
        line-height: 36px;
        font-weight: 900;
    }
    p {
        color: #606060;
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
    }
    >div {
        text-align: center;
        margin-top: 20px;
        img {
            margin-bottom: 20px;
            width: 88%;
        }
        &.btn_wrp {
            margin-top: 35px;
            button {
                border: 1px solid transparent;
                border-radius: 4px;
                height: 53px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                line-height: 16px;
                font-weight: 500;
                width: 242px;
                background-color: #fff;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                &:first-child {
                    background-color: #005287;
                    color: #fff;
                }
                &:last-child {
                    border-color: #005287;
                    color: #005287;
                }
            }
        }
    }
}
// assign plan modal
.plan_details {
    flex-wrap: wrap;
    &_item {
        // max-width: calc(100% - 152px);
        flex-direction: row;
        align-items: center;
        img {
            margin-right: 16px;
            width: 92px;
            height: 92px;
            object-fit: contain;
            border-radius: 4px;
        }
        h5 {
            color: #33759F;
            text-transform: uppercase;
            font-size: 20px;
            margin-bottom: 0;
        }
    }
    .licenses_count {
        // max-width: 150px;
        // width: 100%;
        text-align: right;
    }
    &_action {
        width: 256px;
        button {
            width: 120px;
        }
    }
}