.btn-search:hover {
    box-shadow: 2px 2px 5px #00000091;
    transform: scale(1.02);
}

/// news ticker ///
.vertical-news-ticker {
    height: 24px;
    overflow: hidden;
}
.ticker-container {
    display: flex;
    flex-direction: column;
    animation: ticker-animation 4s linear infinite;
}
.ticker-item {
    padding: 8px;
    transition: transform 0.5s ease-in-out;
}
.current {
    color: #fff;
}
.vertical-news-ticker.paused .ticker-container {
    animation-play-state: paused !important;
}
@keyframes ticker-animation {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-90%);
    }
}