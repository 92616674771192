.drag-main {
    display: flex;
    width: 100%;
    height: 100%;
    .drag-area {
        width: 600px;
        height: 100%;
        .svg-container {
            width: 600px;
            height: 100%;
            text-align: center;
            svg {
                width: 600px;
                height: 100%;
                margin: 0 auto;
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}