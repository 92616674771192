.my_org_main_new {
    position: relative;

    .head {
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .MuiPaper-root {
        padding: 20px;
    }
    .org_table {
        .MuiPaper-root {
            padding-left: 0;
            .MuiTable-root{
                width: unset !important;
            }
        }
        .table_paper {
            padding: 20;
            padding-bottom: 0;
        }
        .MuiTableCell-root {
            border-bottom: 0;
            letter-spacing: 1px;
            color: #afaaaa;
            font-weight: 400;
            font-size: 14px;
            padding-left: 0;
            b {
                color: #03639d;
            }
        }
        .org_table_body {
            .MuiTableCell-root {
                width: unset !important;
                min-width: unset !important;
            }
        }
    }
    .org_profile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        .org_profile_img {
            max-width: 115px;
            max-height: 115px;
            border-radius: 50%;
            border: 2px solid #E9E9E9;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        .org_profile_details {
        font-size: 24px;
        font-weight: 700;
        }
    }
    .address-cards{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
        grid-gap: 20px;

        .add-address{
            border: 2px solid #005287;
            border-style: dashed;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            color: #005287;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover{
                background-color: #005287;
                color: #fff;
            }
        }
        .address_paper{
            p{
                font-size: 14px;
                font-weight: 400;
            }
            background: #F8F8F8;
            &.default{
                background: #E3F5FD;
                position: relative;
                .default_address{
                    svg{
                        position: absolute;
                        top: -5px;
                        right: -5px;
                        fill: #005287;
                        font-size: 25px;
                        font-weight: 400;
                    }
                }
            }
        }
        &.template-cards{
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

            .address_paper{
                // overflow: hidden;
                align-items: center;
                display: flex;
                padding: 16px;
                cursor: pointer;
            }
            .template_preview{
                width: 200px;
                height: 250px;
                // display: contents;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                }
            }
        }
    }
}
.org_address_popup{

    .org_address_popup_multi_field{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
}

.org_table_profile_td{
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 5px 0;
    .org_table_profile_name{
        p{
            font-size: 16px;
            font-weight: 700;

        }
        span{
            font-size: 12px;
            font-weight: 400;
            color: #03639D;
        }
        
    }
}
.add_template_select{
    .MuiSelect-select{
        padding: 10px 14px;
    }
}
.organization-hr {
    margin: 20px 0;
}

.add_template_description{
    min-height: 100px;
    border: 1px solid #E9E9E9;
    .add_template_description_editor, .MUIRichTextEditor-editorContainer-19 {
        padding-inline: 10px;
    }
}
.invoice_template_form_side{
    max-width: 400px;
    width: 100%;
    .image_upload_img{
        .close_modal{
            z-index: 8;
        }
    }
}
.invoice_main{
    display: flex;
}

.margin-left-0{
    margin-left: 0 !important;
}
.template_card{
    flex-direction: column;
    position: relative;
    gap: 10px;
    padding-bottom: 10px !important;
    .template_preview{
        width: 100% !important;
        height: 250px !important;
        overflow: hidden !important;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    
    }
    .card_icon{
        position: absolute;
        right: 5px;
        top: 5px;
    }
    .card_details{
        width: 100%;
        text-transform: capitalize;
        text-align: left;
    }
}


.columns_setting_grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.columns_setting_footer{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 10px;
    border-top: 1px solid #E9E9E9;
    button{
        padding: 10px 20px;
    }
}

.date-picker-add-expense{
    .MuiInputBase-input{
        padding: 10px 14px;
    }
}

.expanse-table-invoice{
    &:hover{
        background: #F8F8F8;
    }
}